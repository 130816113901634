import CustomSelect from "components/Common/CustomSelect";
import Loader from "components/Common/Loader";
import { getAssetImgSrc } from "helpers/assetImgSrc";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWallets } from "store/actions";
import {
  Input,
  InputGroup,
  InputGroupText,
  Label
} from "reactstrap";
import AsiaPayWalletDetails from "./AsiaPayWalletDeposit";

export default function FiatFormDetails(props) {
  const {
    t,
    selectedWallet,
    amount,
    setAmount,
    setSelectedWallet,
    setAmountValidation,
    baseCurrency,
    conversionRate,
    targetCurrency,
    setTargetCurrency,
    gateway,
    amountValidation
  } = props;
  const dispatch = useDispatch();
  const [amountError, setAmountError] = useState(false);
  const [minAmount, setMinAmount] = useState(1);
  const { wallets, loading } = useSelector((state) => state?.walletReducer);

  useEffect(() => {
    if (parseFloat(amount) < minAmount) {
      setAmountError(true);
      setAmountValidation(true);
    } else {
      setAmountError(false);
      setAmountValidation(false);
    }
  }, [amount, minAmount, selectedWallet]);

  useEffect(() => {
    const sWallet = wallets?.find((wallet) => wallet._id === selectedWallet?.value);
    setMinAmount(parseInt(sWallet?.assetId?.minAmount?.deposit) || minAmount);
  }, [selectedWallet]);

  useEffect(() => {
    if (wallets?.length > 0) {
      setSelectedWallet({
        label: wallets[0].asset,
        value: wallets[0]._id,
        image: getAssetImgSrc(wallets[0].assetId)
      });
      setMinAmount(parseInt(wallets[0].assetId?.minAmount?.deposit) || 15);
    }
  }, [wallets]);

  const loadWallets = () => {
    dispatch(fetchWallets());
  };

  useEffect(() => {
    if (!wallets || wallets?.length === 0) {
      loadWallets({
        isCrypto: false,
        isInventory: false,
      });
    }
  }, []);

  const fiatWallets = wallets?.map((wallet) => {
    return {
      label: wallet.asset,
      value: wallet._id,
      image: getAssetImgSrc(wallet.assetId)
    };
  });

  return (
    <div className="w-100">
      <>{gateway === "ASIA_BANKS" ? <AsiaPayWalletDetails {...props}></AsiaPayWalletDetails> : <>
        {loading ? <Loader /> : (
          <>
            {t("Transaction requirements")}
            <i className="fas fa-info-circle ms-2"></i>
            <div className="mt-3">
              <InputGroup>
                <Input
                  required
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  className="form-control"
                  type="text"
                  min="0"
                  value={amount}
                  placeholder={`Enter ${minAmount}-128341`}
                  onKeyPress={(e) => {
                    if (!/[0-9.]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                <InputGroupText className="custom-input-group-text p-0 border-0">
                  <div style={{
                    width: "127px",
                    height: "100%"
                  }}
                  >
                    <CustomSelect
                      placeholder="Select"
                      name="wallets"
                      isRequired
                      options={fiatWallets}
                      defaultValue={fiatWallets[0]}
                      onChange={(e) => {
                        setSelectedWallet(e);
                      }}
                    >
                    </CustomSelect>
                  </div>
                </InputGroupText>
              </InputGroup>
            </div>
            {amountValidation && (
              <p className="small text-danger "> {
                !amount ? t("Amount is required") : t("Amount must be greater than " + minAmount)
              } </p>
            )}
          
            {/* <div className="text-center fw-bolder mt-4 received-amount">
            <span className="fs-5">{selectedWallet?.label}</span>
            <span className="fs-1">{amount}</span>
          </div> */}
          </>
        )}
        {
          selectedWallet && conversionRate !== 0 && amount > 0 && selectedWallet?.label !== baseCurrency && (
            <div className="text-center mt-4">
              <span className="fs-5">{t("Conversion Rate: " + amount + ` ${selectedWallet?.label}` + " = " + `${parseFloat(conversionRate?.toFixed(3) * parseFloat(amount))?.toFixed(3)}` + ` ${baseCurrency}` )}</span>
            </div>
          )
        }
      </>}</>

    </div>
  );
}
