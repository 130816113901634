import {
  POST_Withdraw_START,
  POST_Withdraw_SUCCESS,
  POST_Withdraw_FAIL,
  PESKA_WITHDRAWAL_START,
  PESKA_WITHDRAWAL_SUCCESS,
  PESKA_WITHDRAWAL_FAIL
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  message: "",
  peskaWithdrawalLoading: false,
  peskaWithdrawalError: "",
  peskaWithdrawalMessage: ""
};

const withdrawReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_Withdraw_START:
      return {
        ...state,
        loading: true
      };
    case POST_Withdraw_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload
      };
    case POST_Withdraw_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case PESKA_WITHDRAWAL_START:
      return {
        ...state,
        peskaWithdrawalLoading: true
      };
    case PESKA_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        peskaWithdrawalLoading: false,
        peskaWithdrawalMessage: action.payload
      };
    case PESKA_WITHDRAWAL_FAIL:
      return {
        ...state,
        peskaWithdrawalLoading: false,
        peskaWithdrawalError: action.payload
      };
    default:
      return state;
  }
};

export default withdrawReducer;
