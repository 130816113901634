/* eslint-disable no-useless-catch */
import * as axiosHelper from "./api_helper";

export const getWallets = async () => {
  const data = await axiosHelper.get("/wallets");
  if (data.isError) {
    throw new Error(data.isError);
  }
  return data?.result;
};

export const addWithdraw = async (values) => {
  try {
    let endpoint = "transactions/withdraw";
    let payload = values;

    // Handle Peska withdrawal differently
    if (values.gateway === "PESKA") {
      endpoint = "/psp/peska/withdrawal";
      const email = JSON.parse(values.payload)?.email;
      payload = {
        amount: values.amount,
        email: email,
        currency: values.currency,
        ...(values.tradingAccountId && { tradingAccountId: values.tradingAccountId }),
        ...(values.walletId && { walletId: values.walletId })
      };
    }

    const data = await axiosHelper.post(endpoint, payload);
    return data;
  } catch (error) {
    throw error;
  }
};

export const peskaWithdraw = async (values) => {
  try {
    const response = await axiosHelper.post("/psp/peska/withdrawal", {
      amount: values.amount,
      email: values.email,
      ...(values.tradingAccountId && { tradingAccountId: values.tradingAccountId }),
      ...(values.walletId && { walletId: values.walletId })
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addWithdrawCrypto = async (values) => {
  // const { id, values } = payload;
  const data = await axiosHelper.post("transactions/withdraw/crypto", values);
  // if (data.isError) {
  //   throw new Error(data.message);
  // }
  return data;
};

export const addFxWithdraw = async (values) => {
  try {
    let endpoint = "fxtransactions/withdrawals";
    let payload = values;

    // Handle Peska withdrawal differently
    if (values.gateway === "PESKA") {
      endpoint = "/psp/peska/withdrawal";
      // const email = JSON.parse(values.payload)?.email;
      payload = {
        amount: values.amount,
        email: values.email,
        currency: values.currency,
        ...(values.tradingAccountId && { tradingAccountId: values.tradingAccountId })
      };
    }

    const data = await axiosHelper.post(endpoint, payload);
    return data;
  } catch (error) {
    throw error;
  }
};
// export const fetchwallets = async () => {
//     const data = await axiosHelper.get('wallets');
//     return data.result;
// }
