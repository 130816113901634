const PESKA_SUCCESS_URL = "https://clients.xeone.com/reports";
const PESKA_FAILURE_URL = "https://clients.xeone.com/reports";
const baseURL = "https://clients.xeone.com";
// process.env.NODE_ENV === "development"
//   ? "https://c1b8-156-203-233-163.ngrok-free.app"
//   : "https://clients.xeone.com";
const PESKA_CALLBACK_URL = `${baseURL}/api/v1/cp/psp/peska/transfer/callback`;
   
export {
  PESKA_SUCCESS_URL, PESKA_FAILURE_URL, PESKA_CALLBACK_URL
};